import React, { useState, useEffect } from "react";

import fetchData from "../../api";
import Banner from "./Banner";
import Navbar from "./Navbar";

const Header = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(fetchData());
  }, []);

  return (
    <div>
      <Banner />
      <Navbar email={data.email} notificationsCount={data.notificationsCount} />
    </div>
  );
};

export default Header;
