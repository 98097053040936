const types = {
  primary: "bg-primary text-white",
  secondary: "bg-whitish text-steel",
};
const Button = ({ onClick, children, type = "primary" }) => (
  <div
    onClick={onClick}
    className={`h-[36px] w-fit cursor-pointer rounded-[12px] px-[16px] py-[8px] text-sm ${types[type]}`}
  >
    {children}
  </div>
);

export default Button;
