import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { ReactComponent as Dollar } from "../../assets/icons/dollar.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Medal } from "../../assets/icons/medal.svg";
import { ReactComponent as Question } from "../../assets/icons/seal_question.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import IconWrapper from "../../common/IconWrapper";

const sidebarItems = [
  ["Home", "/", Home],
  ["Favorites", "/Favorites", Heart],
  ["Orders", "/Orders", Dollar],
  ["Rewards", "/Rewards", Medal],
  ["Balance", "/Balance", Wallet],
  ["Tickets", "/Tickets", Question],
];

const NavList = () => {
  const location = useLocation();
  return (
    <div>
      {sidebarItems.map(([text, to, Icon], i) => {
        const isActive = to === location.pathname;
        return (
          <Link
            to={to}
            className={`flex cursor-pointer items-center py-[12px] pl-[8px] ${
              isActive ? "rounded-[12px] bg-whitish text-black" : "text-steel"
            }`}
            key={i}
          >
            <div className="mr-[8px]">
              <IconWrapper
                Icon={Icon}
                size="s"
                color={isActive ? "black" : "steel"}
              />
            </div>

            <div>{text}</div>
          </Link>
        );
      })}
      <div className="flex cursor-pointer items-center py-[12px] pl-[8px]	text-primary-darker mt-[6px]">
        <div className="mr-[8px]">
          <IconWrapper Icon={Logout} color="primaryDarker" size="s" />
        </div>

        <div>Log out</div>
      </div>
    </div>
  );
};

export default NavList;
