import { ReactComponent as Bag } from "../../assets/icons/bag.svg";
import { ReactComponent as CaretDown } from "../../assets/icons/caretdown.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import profile from "../../assets/images/profile.png";
import IconWrapper from "../../common/IconWrapper";
import Input from "../../common/Input";

const navbarItems = ["Products", "Contact", "Reviews", "FAQ"];

const ProfileSection = ({ email }) => (
  <div className="flex h-[44px] items-center rounded-[12px] bg-whitish p-[6px]">
    <div className="mr-[8px] h-[32px] w-[32px]">
      <img src={profile} alt="profile" />
    </div>
    <div className="mr-[8px]">{email}</div>
    <IconWrapper Icon={CaretDown} color="grayish" size="s" />
  </div>
);

const Navbar = ({ email, notificationsCount }) => (
  <div className="items-center border-b-[1px] border-pearl px-[40px] py-[10px] xl:flex xl:h-[80px] xl:py-0">
    <div className="w-full items-center md:flex">
      <div className="mr-[40px]">
        <Logo />
      </div>
      <Input
        className="mt-[10px] md:mt-0 xl:mr-[24px]"
        placeholder="Search for products..."
        icon={<IconWrapper Icon={Search} color="black" size="s" />}
      />
    </div>

    <div className="mt-[10px] md:flex xl:mt-0 ">
      <div className="flex items-center">
        {navbarItems.map((e, i) => (
          <div key={i} className="mr-[24px]">
            {e}
          </div>
        ))}
      </div>
      <div className="mt-[10px] flex items-center xl:mt-0">
        <div className="mr-[16px]">
          <IconWrapper color="black" size="m" Icon={Heart} />
        </div>

        <div className="relative mr-[16px]">
          <IconWrapper Icon={Bag} color="black" size="m" />
          <div className="flex-center absolute right-[8px] top-[8px] h-[12px] w-[12px] rounded-full bg-primary text-xxs text-white">
            <div>{notificationsCount}</div>
          </div>
        </div>
        <ProfileSection email={email} />
      </div>
    </div>
  </div>
);

export default Navbar;
