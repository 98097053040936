const fetchData = () => {
  return {
    email: "qwerty@mail.ru",
    notificationsCount: 2,
    balance: "$0.00",
    charts: {
      data: [
        {
          value: 4000,
        },
        {
          value: 3000,
        },
        {
          value: 2000,
        },
        {
          value: 2780,
        },
        {
          value: 1890,
        },
        {
          value: 2390,
        },
        {
          value: 3490,
        },
      ],
      referrals: "0 person",
      earned: "$0.00",
      totalRevenue: "$0.00",
      earnPercent: "5%",
    },
    stats: {
      customerSince: "Nov 21, 2018",
      totalSpent: "$99.99",
      top: "#25",
      averageReview: "4.95",
      topSpent: "$257.99 spent",
      purchases: 24,
      visits: 247,
      poweredBy: "Billgang",
      store: "D4RK",
      favoriteProduct: "bought 6 times",
      game_1: {
        name: "Game V1.0",
        desc: "This is the short description for a product, the short description for a product of course. This is the short description for a product, the short description for a product of course",
      },
      game_2: {
        name: "Game V2.0",
        desc: "This is the short description for a product, the short description for a product of course..",
      },
    },
  };
};

export default fetchData;
