import profile from "../../assets/images/profile.png";

const Card = ({ email, balance }) => (
  <div className="mb-[16px] flex h-[160`px] w-full flex-col items-center rounded-[16px] bg-whitish py-[16px]">
    <div className="mb-[8px] h-[64px] w-[64px]">
      <img src={profile} alt="profile" />
    </div>
    <div className="mb-[4px] font-bold">{email}</div>
    <div className="rounded-[12px] bg-primary-lighter px-[8px] py-[4px] text-sm text-primary">
      Balance: {balance}
    </div>
  </div>
);
export default Card;
