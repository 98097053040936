const iconSizes = {
  s: "w-[17px] h-[17px]",
  m: "w-[21px] h-[21px]",
  l: "w-[28px] h-[28px] ",
};

const colorVariants = {
  primary: "fill-primary",
  black: "fill-black",
  steel: "fill-steel",
  grayish: "fill-grayish",
  primaryDarker: "fill-primary-darker",
};

const container = {
  s: "",
  m: "icon-wrapper w-[44px] h-[44px]",
  l: "icon-wrapper w-[48px] h-[48px]",
};

const IconWrapper = ({ Icon, color = "primary", size }) => (
  <div className={`${container[size]}`}>
    <div className={`${colorVariants[color]} ${iconSizes[size]}`}>
      {Icon && <Icon />}
    </div>
  </div>
);

export default IconWrapper;
