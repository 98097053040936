import React, { useState, useEffect } from "react";

import fetchData from "../../api";
import Card from "./Card";
import NavList from "./NavList";

const Sidebar = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(fetchData());
  }, []);

  return (
    <div className="w-full border-r-[1px] border-pearl px-[40px] pt-[24px] lg:max-w-[320px] lg:pr-[16px]">
      <Card email={data.email} balance={data.balance} />
      <NavList />
    </div>
  );
};

export default Sidebar;
