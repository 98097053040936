import React, { useState, useEffect } from "react";

import fetchData from "../api";
import { ReactComponent as Bag } from "../assets/icons/bag.svg";
import { ReactComponent as Billgang } from "../assets/icons/billgang.svg";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Crown } from "../assets/icons/crown.svg";
import { ReactComponent as D4RK } from "../assets/icons/d4rk.svg";
import { ReactComponent as Dollar } from "../assets/icons/dollar.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as Heart } from "../assets/icons/heart.svg";
import { ReactComponent as Medal } from "../assets/icons/medal.svg";
import { ReactComponent as Star } from "../assets/icons/star.svg";
import game_1 from "../assets/images/game_1.png";
import game_2 from "../assets/images/game_2.png";
import ChartItem from "../components/ChartItem";
import ReferralBlock from "../components/ReferralBlock";
import StatItem from "../components/StatItem";

const Home = () => {
  const [data, setData] = useState();
  useEffect(() => {
    setData(fetchData());
  }, []);

  if (!data) return null;
  const { stats, charts } = data;
  return (
    <div className="grid w-full grid-cols-1 grid-rows-[repeat(4,min-content)] gap-[16px] p-[24px_40px] sm:grid-cols-2 xl:grid-cols-4 xl:p-[24px_40px_24px_24px]">
      <div className="col-span-1 row-span-2 mb-[24px] sm:col-span-2 xl:mb-0">
        <ReferralBlock />
      </div>
      <ChartItem
        title="Referrals"
        value={charts.referrals}
        data={charts.data}
      />
      <ChartItem title="Earned" value={charts.earned} data={charts.data} />
      <ChartItem
        title="Total Revenue"
        value={charts.totalRevenue}
        data={charts.data}
      />
      <ChartItem
        title="Earn Percent"
        value={charts.earnPercent}
        data={charts.data}
      />

      <div className="col-span-1 h-[24px] text-lg font-bold sm:col-span-2 xl:col-span-4">
        Your statistics
      </div>
      <div className="mr-[16px] w-full">
        <StatItem
          Icon={Calendar}
          title="Customer since"
          value={stats.customerSince}
        />
        <StatItem Icon={Dollar} title="Total spent" value={stats.totalSpent} />
        <StatItem Icon={Medal} title="Top" value={stats.top} />
        <StatItem
          Icon={Star}
          title="Average review"
          value={stats.averageReview}
        />
      </div>
      <StatItem
        image={game_1}
        imageDesc={stats.game_1.desc}
        imageName={stats.game_1.name}
        Icon={Crown}
        title="Top spent"
        value={stats.topSpent}
      />
      <div className="mr-[16px] w-full">
        <StatItem Icon={Bag} title="Purchases" value={stats.purchases} />
        <StatItem Icon={Eye} title="Visits" value={stats.visits} />
        <StatItem Icon={Billgang} title="Powered by" value={stats.poweredBy} />
        <StatItem Icon={D4RK} title="Store" value={stats.store} />
      </div>
      <StatItem
        image={game_2}
        imageDesc={stats.game_2.desc}
        imageName={stats.game_2.name}
        Icon={Heart}
        title="Favorite product"
        value={stats.favoriteProduct}
      />
    </div>
  );
};

export default Home;
