import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Template from "./pages/Template";

function App() {
  return (
    <div className="flex h-screen flex-col">
      <BrowserRouter>
        <Header />
        <div className="flex flex-grow flex-col lg:flex-row">
          <Sidebar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Template />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
